import { compose, lifecycle, mapProps, withHandlers, withState, withProps } from 'recompose'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import { findScrollable, findStickyChildren, isBrowser, listen, unlisten } from '../utils/ux'
import withRefsStore from './withRefsStore'

const makeWithHandlers = withHandlers({
  checkStickyState: ({ refs, nodes: { scrollNode, ownNode }, setSticky, isSticky, setStickyOffset, stickyOffset }) => throttle(() => {
    if (scrollNode && ownNode && isFunction(scrollNode.getBoundingClientRect) && isFunction(ownNode.getBoundingClientRect)) {
      const ownRect = ownNode.getBoundingClientRect()
      const scrollRect = scrollNode.getBoundingClientRect()
      let currentOffset = 0
      const priorStickies = findStickyChildren(scrollNode, ownNode)
      for (let child of priorStickies) {
        currentOffset += child.offsetHeight
      }
      const shouldBeSticky = isSticky ? (scrollNode.scrollTop + ownRect.height + currentOffset) > (ownRect.top - ownRect.height) : ownRect.top < (scrollRect.top + currentOffset)
      if (isSticky !== shouldBeSticky) {
        setSticky(shouldBeSticky)
      }
      if (stickyOffset !== currentOffset && currentOffset >= 0) {
        setStickyOffset(currentOffset)
      }
    }
  }, 100)
})

const makeWithLifecycle = lifecycle({
  componentDidMount () {
    const { refs, checkStickyState, setNodes, nodeName } = this.props
    const scrollNode = isBrowser() && findScrollable(refs.get(nodeName))
    if (scrollNode) {
      setNodes({
        scrollNode,
        ownNode: refs.get(nodeName)
      })
      listen(scrollNode, ['scroll', 'resize', 'pageshow', 'load'], checkStickyState)
    }
  },
  componentDidUpdate (prevProps) {
    const { checkStickyState, stickyUpdateHash } = this.props
    if (!isEqual(stickyUpdateHash, prevProps.stickyUpdateHash)) {
      checkStickyState()
    }
  },
  componentWillUnmount () {
    const { checkStickyState, nodes: { scrollNode } } = this.props
    if (scrollNode) {
      unlisten(scrollNode, ['scroll', 'resize', 'pageshow', 'load'], checkStickyState)
    }
  }
})

const makeMapProps = mapProps(({ refs, nodes, setNodes, checkStickyState, setSticky, ...props }) => {
  return {
    ...props
  }
})

export default compose(
  withProps({
    nodeName: `sticky_${Math.random()}`
  }),
  withState('isSticky', 'setSticky', false),
  withState('stickyOffset', 'setStickyOffset', 0),
  withState('nodes', 'setNodes', {}),
  withRefsStore,
  makeWithHandlers,
  makeWithLifecycle,
  makeMapProps
)
