import styled, { css } from 'styled-components'
import keys from 'lodash/keys'
import { hexToRgb } from '../../../../utils/ux'

export default styled.div`
  ${({ sticky, isSticky }) => sticky && !isSticky && 'top: 0;'}
  ${({ isSticky, position }) => isSticky ? 'position: sticky;' : position && `position: ${position};`}
  ${({ isSticky, stickyOffset = 0 }) => isSticky && `top: ${stickyOffset}px;`}
  ${({ isSticky, zIndex = 2000 }) => isSticky && `z-index: ${zIndex};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ whitespace }) => whitespace && `white-space: ${whitespace};`}
  ${({ stretch }) => stretch && 'align-self: stretch;'}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  ${({ theme, paddingTop, paddingBottom, paddingLeft, paddingRight, marginTop, marginBottom, marginLeft, marginRight }) => keys(theme.constants.breakpoints).map(bp => css`
    @media (min-width: ${theme.constants.breakpoints[bp]}px) {
      ${marginTop && `margin-top: ${theme.constants.spacings[marginTop][bp]}px;`}
      ${marginBottom && `margin-bottom: ${theme.constants.spacings[marginBottom][bp]}px;`}
      ${marginLeft && `margin-left: ${theme.constants.spacings[marginLeft][bp]}px;`}
      ${marginRight && `margin-right: ${theme.constants.spacings[marginRight][bp]}px;`}
      ${paddingTop && `padding-top: ${theme.constants.spacings[paddingTop][bp]}px;`}
      ${paddingBottom && `padding-bottom: ${theme.constants.spacings[paddingBottom][bp]}px;`}
      ${paddingLeft && `padding-left: ${theme.constants.spacings[paddingLeft][bp]}px;`}
      ${paddingRight && `padding-right: ${theme.constants.spacings[paddingRight][bp]}px;`}
    }`
  )}

  ${({ theme, isSticky, withShadow }) => {
    const rgbArr = hexToRgb(theme.components.gridContainer.shadow.color)
    return (isSticky && withShadow) ? keys(theme.constants.breakpoints).map(bp => css`
      @media (min-width: ${theme.constants.breakpoints[bp]}px) {
        box-shadow: ${theme.components.gridContainer.shadow.sizes[bp].x}px ${theme.components.gridContainer.shadow.sizes[bp].y}px ${theme.components.gridContainer.shadow.sizes[bp].blur}px ${theme.components.gridContainer.shadow.sizes[bp].spread}px rgba(${rgbArr[0]}, ${rgbArr[1]}, ${rgbArr[2]}, ${theme.components.gridContainer.shadow.opacity});
      }`
    ) : ''
  }}
`
