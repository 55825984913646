import { compose, withProps } from 'recompose'
import hash from 'object-hash'
import { findDOMNode } from 'react-dom'

const hashOptions = {
  algorithm: 'md5',
  ignoreUnknown: true,
  respectFunctionProperties: true,
  respectType: true
}

class RefsStore {
  constructor () {
    this.refs = new Map()
    this.watchers = new Map()
  }

  _store (name, ref) {
    this.refs.set(name, ref)
    const node = findDOMNode(ref)
    if (node) {
      this.watchers.forEach((watcher, handle) => {
        if (watcher.name === name) {
          watcher.cb(node)
          this.watchers.set(handle, { ...watcher, node })
        }
      })
    }
  }

  store (name, ref) {
    if (!ref) return (fRef) => this._store(name, fRef)
    this._store(name, ref)
  }

  get (name) {
    return this.refs.get(name)
  }

  watch (name, cb) {
    const handle = hash(cb, hashOptions)
    if (this.refs.has(name)) {
      const node = findDOMNode(this.refs.get(name))
      if (node) {
        cb(node)
      }
    }
    this.watchers.set(handle, { cb, name })
    return handle
  }

  unwatch (handle, cb) {
    if (this.watchers.has(handle)) {
      const watcher = this.watchers.get(handle)
      if (watcher.node && cb) {
        cb(watcher.node)
      }
      this.watchers.delete(handle)
    }
  }
}

export const refs = new RefsStore()

export default compose(
  withProps({ refs })
)
