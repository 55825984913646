import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import { compose, setPropTypes, branch, onlyUpdateForKeys, lifecycle } from 'recompose'
import Container from './Container'
import withStickyState from '../../../hocs/withStickyState'

const propTypes = {
  styles: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  sticky: PropTypes.bool,
  withShadow: PropTypes.bool,
  minHeight: PropTypes.string,
  zIndex: PropTypes.number,
  onMount: PropTypes.func,
  withBeforeSpacer: PropTypes.bool,
  maxWidth: PropTypes.string
}

export default compose(
  setPropTypes(propTypes),
  branch(
    ({ sticky }) => !!sticky,
    compose(withStickyState),
    f => f
  ),
  branch(
    ({ onMount }) => isFunction(onMount),
    compose(
      lifecycle({
        componentDidMount () {
          this.props.onMount()
        }
      })
    ),
    f => f
  ),
  onlyUpdateForKeys(['isSticky', 'stickyOffset', 'children'])
)(Container)
