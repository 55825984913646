import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import trimStart from 'lodash/trimStart'
import { Container as ElementContainer } from '../components/elements'
import { graphql, StaticQuery, navigate, Link } from 'gatsby'
import { MarkdownContent } from '../components/MarkdownRenderer'
import styled, { withTheme } from 'styled-components'
import { useSpring } from 'react-spring/dist/web'
import { computeCurrentBreakpoint, isBrowser, listen, unlisten } from '../utils/ux'
import { AnimatedFigure } from '../components/MemoMapping'
import Event from '../components/Event'

const heightMap = {
  md: '25vh',
  lg: '25vh',
  xl: '25vh',
  xxl: '30vh',
  xxxl: '35vh'
}
const Card = styled(AnimatedFigure)`
  height: ${({ currentBreakpoint }) => heightMap[currentBreakpoint]};
  width: 100%;
  background: grey;
  border-radius: 5px;
  background-image: url(${({ imgURI }) => imgURI});
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 5px solid white;
  align-self: center;
  margin: auto;
  margin-top: 1px;
  cursor: pointer;
  z-index: 1;

  :hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
  }
`

const ComposedCard = React.memo((parentProps) => {
  const [props, set] = useSpring({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } })
  return (
    <Card
      {...parentProps}
      className={'image is-1by1'}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    />
  )
})

const calc = (x, y) => [-(y - window.innerHeight / 2) / 50, (x - window.innerWidth / 2) / 50, 1.05]
const trans = (x, y, s) => `perspective(80vw) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

/* eslint-disable */
const LandingPage = React.memo(({ theme, pages = [], events = [], welcome, location }) => {
  // waiting for scrollNode to get ready, required for parallax component
  const currentHash = trimStart(get(location, 'hash', ''), '#')
  let contentItem = isEmpty(currentHash)
    ? welcome
    : pages.find(({ node }) => node.frontmatter.path === currentHash).node
  const lastEvent = get(events, '[0].node', false)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(computeCurrentBreakpoint(theme))
  useEffect(() => {
    const handler = () => {
      setCurrentBreakpoint(computeCurrentBreakpoint(theme))
    }
    if (isBrowser()) {
      listen(window, ['resize'], handler)
    }
    return () => {
      unlisten(window, ['resize'], handler)
    }
  }, [location])
  return (
    <>
      <Helmet title={'Ihr Impuls zum Ziel'} />
      <ElementContainer sticky withShadow backgroundColor={theme.constants.colors.headerBackground}>
        <div className={'container is-widescreen'} >
          <div className={`tabs is-centered ${currentBreakpoint === 'md' ? 'is-small' : 'is-medium'} is-fullwidth`}>
            <ul style={{ maxWidth: '100%', flexWrap: 'wrap' }}>
              <li className={currentHash === '' && 'is-active'}>
                <Link to={'/'} first active={currentHash === ''}>
                  <span>{'Willkommen'}</span>
                </Link>
              </li>
              {
                pages.map((page, index) => {
                  const isActive = currentHash === get(page, 'node.frontmatter.path')
                  return (
                    <li key={index} className={isActive && 'is-active'}>
                      <Link to={`/#${get(page, 'node.frontmatter.path')}`} active={isActive}>
                        <span>{get(page, 'node.frontmatter.title', index)}</span>
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </ElementContainer>
      <div className={'container is-widescreen m-t-xl m-b-xl'}>
        <div className="tile is-ancestor">
          <div className="tile is-vertical is-8">
            <div className="tile is-parent">
              <article className='message'>
                <div className='message-header'>
                  <p>{contentItem.frontmatter.title}</p>
                </div>
                <div className='message-body'>
                  <MarkdownContent html={get(contentItem, `html`)} />
                </div>
              </article>
            </div>
          </div>
          <div className="tile is-parent">
            <Event
              header={'Aktuelles Event'}
              slug={get(lastEvent, 'fields.slug')}
              title={get(lastEvent, 'frontmatter.title')}
              date={get(lastEvent, 'frontmatter.date')}
              excerpt={get(lastEvent, 'excerpt')}
            />
          </div>
        </div>
      </div>
      <div className={'container is-widescreen m-b-xl'}>
        {
          (currentHash === '') &&
            <>
              <h1 className="title">Leistungen</h1>
              <div className='columns is-multiline'>
                {
                  pages.map(({ node }, index) => (
                    <div key={index} className={'column is-half'}>
                      <div className='card' style={{ height: '100%' }}>
                        <div className={'card-header'}>
                          <div className={'card-header-title'}>
                            {get(node, `frontmatter.title`)}
                          </div>
                        </div>
                        <ComposedCard imgURI={get(node, `frontmatter.image.childImageSharp.fluid.src`)} onClick={() => navigate(`/#${get(node, `frontmatter.path`, '')}`)} currentBreakpoint={currentBreakpoint} />
                        <div className={'card-content'}>
                          <MarkdownContent html={get(node, `fields.excerptHTML`)} />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
          </>
        }
      </div>
    </>
  )
})

export default withTheme((rootProps) => (
  <StaticQuery query={indexQuery} render={({ pages, events, welcome }) => (
    <LandingPage {...rootProps} pages={pages.edges} events={events.edges} welcome={welcome} />
  )} />
))

const indexQuery = graphql`
  query {
    welcome: markdownRemark (frontmatter: { path: { eq: "/" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid (maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    events: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "EventPage"} } },
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "dddd, DD MMMM YYYY HH:mm", locale: "de")
            title
          }
        }
      }
    }
    pages: allMarkdownRemark(filter: { frontmatter: { landing: { eq: true} } }, sort: { order: ASC, fields: [frontmatter___order]}) {
      edges {
        node {
          html
          fields {
            excerptHTML
          }
          frontmatter {
            title
            path
            order
            image {
              childImageSharp {
                fluid (maxWidth: 2048) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
